
















































import CommonProfileImage from 'common-modules/src/components/CommonProfileImage.vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import PostContent from './PostContent.vue';
import jwlPostComment from './jwlPostComment.vue';
import { CommentedDiscussionPost } from '@/views/JWL_TaskDiscuss.vue';

const IconComment = () => import('common-modules/src/assets/fa-icons/regular/comment.svg');

@Component({
  name: 'jwl-post-comments',
  components: {
    IconComment,
    CommonProfileImage,
    jwlPostComment,
    PostContent,
  },
})
export default class JwlPostComments extends Vue {
  @Prop(Array)
  postReplies!: CommentedDiscussionPost[];

  @Prop(String)
  postFilter!: string;

  @Prop(Array)
  postContaining!: number[];

  open = false;
  newComments: number[] = [];

  localizedDate (dateString: string): string {
    const date = new Date(dateString);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  }

  createComment (parentId: number): void {
    if (this.newComments.includes(parentId)) {
      this.newComments = this.newComments.filter((postId) => postId !== parentId);
    } else {
      this.newComments.push(parentId);
    }
  }

  postClasses (post: CommentedDiscussionPost): Record<string, boolean> {
    let postFromSelected = false;
    let containedInComments = false;
    let notIncluded = false;

    if (this.postFilter) {
      if (post.author.lmsId === this.postFilter) {
        postFromSelected = true;
      }

      if (this.postContaining.includes(post.id)) {
        containedInComments = true;
      }

      if (!postFromSelected && !containedInComments) {
        notIncluded = true;
      }
    }

    return {
      'jwl-post-comments__comment--post-from-selected': postFromSelected,
      'jwl-post-comments__comment--contained-in-comments': containedInComments,
      'jwl-post-comments__comment--not-included': notIncluded,
    };
  }
}
