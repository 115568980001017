
















































import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CommonProfileImage from 'common-modules/src/components/CommonProfileImage.vue';
import FormElement from 'common-modules/src/components/CommonFormElement.vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import vueFilePond, { VueFilePondComponent } from 'vue-filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import User from 'common-modules/src/store/User';
import JwlSubmitRow from '@/components/JwlSubmitRow.vue';

const STATUS_ERROR = -1;
const STATUS_NOTHING = 0;
const STATUS_SUBMITTING = 1;
const STATUS_SUCCESS = 2;

const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview);

@Component({
  components: {
    JwlSubmitRow,
    CommonProfileImage,
    FormElement,
    FilePond,
  },
  computed: mapGetters([
    'currentUser',
  ]),
})
export default class JwlPostComment extends Vue {
  $refs!: {
    commentForm: HTMLFormElement;
    filePond: VueFilePondComponent;
  }

  currentUser!: User;

  @Prop(Number)
  parentId!: number;

  @Prop({ type: String, default: 'task.addResponse' })
  transKey!: string;

  editor = ClassicEditor;
  editorConfig = {
    toolbar: ['bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'],
    placeholder: this.$t('task.textPlaceholder'),
  };

  text = '';
  syncStatus = STATUS_NOTHING;
  error = null;

  submitComment (): void {
    if (this.$refs.commentForm.reportValidity() && this.text.length > 10 && this.syncStatus !== STATUS_SUBMITTING) {
      this.syncStatus = STATUS_SUBMITTING;
      const FD = new FormData(this.$refs.commentForm);
      FD.append('parentId', this.parentId.toString());
      FD.append('message', this.text);

      this.$refs.filePond.getFiles().forEach((filePondFile) => {
        FD.append('comment-attachments[]', filePondFile.file);
      });

      this.$store.dispatch('postData', {
        url: this.postUrl,
        formData: FD,
      }).then((data) => {
        this.$store.commit('ADD_COMMENT', data);
        this.syncStatus = STATUS_SUCCESS;
        setTimeout(() => {
          this.$emit('post-submitted', this.parentId);
          this.text = '';
          this.syncStatus = STATUS_NOTHING;
          this.error = null;
        }, 2500);
      }).catch((e) => {
        this.error = e;
        this.syncStatus = STATUS_ERROR;
      });
    } else if (this.syncStatus !== STATUS_SUBMITTING) {
      this.syncStatus = 3;
    }
  }

  get postUrl (): string {
    let postUrl = 'task/';
    if (this.$route) {
      postUrl += this.$route.params.task;
      if (this.$route.name === 'grade_discussion') {
        postUrl += `/discuss/${this.$route.params.classroomId}`;
      }
    } else {
      const params = new URLSearchParams(window.location.search);
      const task = params.get('task-code');
      const classroomId = params.get('classroom-id');

      postUrl += task;
      if (classroomId) {
        postUrl += `/discuss/${classroomId}`;
      }
    }
    return postUrl;
  }
}
