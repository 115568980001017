
















































































































import { mapGetters } from 'vuex';
import CommonProfileImage from 'common-modules/src/components/CommonProfileImage.vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import CommonIcon from 'common-modules/src/components/CommonIcon.vue';
import jwlTaskMeta from '@/components/jwlTaskMeta.vue';
import jwlPostComments from '@/components/jwlPostComments.vue';
import jwlPostComment from '@/components/jwlPostComment.vue';
import { DiscussionGrade, DiscussionPost, DiscussionRoot } from '@/store/interface/Discussion';
import { ExtendedRunTask } from '@/store/interface/Task';
import PostContent from '@/components/PostContent.vue';

const BackBar = () => import('@/components/BackBar.vue');
const JwlDiscussionGrading = () => import('@/components/jwlDiscussionGrading.vue');
const CommonError = () => import('common-modules/src/components/CommonError.vue');
const LmsCourseDocument = () => import('@/components/LmsCourseDocument.vue');
const JwlButton = () => import('@/components/JwlButton.vue');

export interface CommentedDiscussionPost extends DiscussionPost {
  comments: CommentedDiscussionPost[];
}

@Component({
  components: {
    BackBar,
    CommonError,
    CommonIcon,
    CommonProfileImage,
    JwlButton,
    LmsCourseDocument,
    JwlDiscussionGrading,
    jwlPostComment,
    jwlPostComments,
    jwlTaskMeta,
    PostContent,
  },
  computed: mapGetters([
    'discussion',
  ]),
})
export default class JwlTaskDiscuss extends Vue {
  $refs!: {
    newCommentContainer: HTMLDivElement;
  }

  discussion!: DiscussionRoot;

  loaded = false;
  task: ExtendedRunTask | null = null;
  error = null;
  newPosts: number[] = [];
  grades: DiscussionGrade[] = [];
  activeFilter: string | null = null;
  containingPosts: number[] = [];
  concentrationCode = '';

  fetchData (): void {
    this.loaded = false;
    const params = new URLSearchParams(window.location.search);
    let course = params.get('course-code');
    let task = params.get('task-code');
    let classroomId = params.get('classroom-id');
    if (this.isLmsView) {
      course = this.$route.params.course;
      task = this.$route.params.task;
      classroomId = this.$route.params.classroomId;
    }
    let route = `task/${task}`;
    if (classroomId) {
      route = `gradebook/${course}/discussion/${task}/${classroomId}`;
    }
    this.$store.dispatch('getData', route)
      .then((data) => {
        this.task = data.task;
        this.$store.commit('SET_DISCUSSION', data.work);
        if (data.grades) {
          this.grades = data.grades;
        }
        this.concentrationCode = data.concentrationCode;
        this.loaded = true;
      })
      .catch((e) => {
        this.error = e;
      });
  }

  createPost (parentId: number): void {
    if (this.newPosts.includes(parentId)) {
      this.newPosts = this.newPosts.filter((postId) => postId !== parentId);
    } else {
      this.newPosts.push(parentId);
    }
  }

  getPostsForId (parentId: number): CommentedDiscussionPost[] {
    const posts = this.discussion.posts.filter((post) => post.parentId === parentId);
    const response: CommentedDiscussionPost[] = [];
    if (posts) {
      posts.forEach((post) => {
        const data: CommentedDiscussionPost = Object.assign(post, {
          comments: this.getPostsForId(post.id),
        });
        response.push(data);
      });
    }
    return response;
  }

  localizedDate (dateString: string): string {
    const date = new Date(dateString);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  }

  scrollToNew (): void {
    const targetRef = this.$refs.newCommentContainer;
    const { top } = targetRef.getBoundingClientRect();

    window.scrollTo({
      top,
      behavior: 'smooth',
    });
  }

  postClasses (post: CommentedDiscussionPost): Record<string, boolean> {
    const isPostFromSelected = !!this.activeFilter && post.author.lmsId === this.activeFilter;
    const containsCommentsFromSelected = !!this.activeFilter && this.containingPosts.includes(post.id);
    const isNoneOfAbove = !!this.activeFilter && !isPostFromSelected && !containsCommentsFromSelected;

    return {
      'jwl-submit-task__post-container--post-from-selected': isPostFromSelected,
      'jwl-submit-task__post-container--contained-in-comments': containsCommentsFromSelected,
      'jwl-submit-task__post-container--not-included': isNoneOfAbove,
    };
  }

  filterByStudent (lmsId: string): void {
    this.activeFilter = lmsId;
    if (lmsId) {
      let parents: number[] = [];
      const posts = this.discussion.posts.filter((post) => post.author.lmsId === lmsId);
      posts.forEach((post) => {
        parents.push(post.parentId);
        if (post.parentId !== this.discussion.id) {
          parents = parents.concat(this.getPostsByParent(post.parentId));
        }
      });
      this.containingPosts = parents;
    } else {
      this.containingPosts = [];
    }
  }

  getPostsByParent (parentId: number): number[] {
    const posts = this.discussion.posts.filter((post) => post.id === parentId);
    let parents: number[] = [];
    posts.forEach((post) => {
      parents.push(post.parentId);
      if (post.parentId !== this.discussion.id) {
        parents = parents.concat(this.getPostsByParent(post.parentId));
      }
    });
    return parents;
  }

  get gradebookRoute (): any | null {
    if (this.$route) {
      const { task, lmsId, course } = this.$route.params;
      return {
        name: 'class',
        params: {
          concentration: this.concentrationCode,
        },
        query: {
          focusCourse: course,
          focusTask: task,
          focusUser: lmsId,
        },
      };
    }
    return null;
  }

  get gradeThemeClass (): Record<string, boolean> {
    return {
      'jwl-submit-task--bachelor': this.task?.programmeType === 'bachelor',
      'jwl-submit-task--professional': this.task?.programmeType === 'professional',
      'jwl-submit-task--teacher-training': this.task?.programmeType === 'teacher-training',
      'jwl-submit-task--academy': this.task?.programmeType === 'academy',
    };
  }

  get structuredDiscussion (): CommentedDiscussionPost[] {
    return this.getPostsForId(this.discussion.id);
  }

  get isAllowedToGrade (): boolean {
    const allowed = ['online_facilitator', 'admin'];
    return !!this.task && allowed.includes(this.task.role);
  }

  get isLmsView (): boolean {
    return typeof this.$route !== 'undefined';
  }

  mounted (): void {
    this.fetchData();

    if (this.isLmsView) {
      this.$watch('$route', this.fetchData);
    }
  }
}
